@import "mixins";
@import "../node_modules/reset-css/reset";

$colors: (
	applejack: (stroke: #fb8240, text: #ffc06d),
	starlight-glimmer: (stroke: #bd88d3, text: #edbcf3),
	sunset-shimmer: (stroke: #d9a006, text: #f8d77b)
);
$heights: (
	header: 20vh,
	img: 65vh,
	figcaption: 5vh,
	footer-line: 5vh
);
$heights: map-merge($heights, (
	picture: map-get($heights, img) + map-get($heights, figcaption),
	footer: map-get($heights, footer-line) * 2
));
$heights: map-merge($heights, (total: map-get($heights, header) + map-get($heights, picture) + map-get($heights, footer)));
$width-total: 100vw;

@function height($height) { @return map-get($heights, $height); }

head, html, body {
	font-family: "Open Sans", "Verdana", sans-serif;
	height: height(total);
	margin: 0;
	overflow: hidden;
	padding: 0;
	width: 100vw;
}

body > main > section {
	.center {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}

	.hidden { display: none; }
	.invisible { visibility: hidden; }

	> div {
		$margin: 1vmin;
		display: flex;
		flex-direction: column;
		height: height(total);
		max-height: height(total);
		max-width: $width-total;
		width: $width-total; // this used to be 100vh for some reason????????

		div#picture {
			height: height(picture);

			> img#placeholder { padding-bottom: height(footer); }

			> figure {
				height: height(picture); 

				> figcaption {
					font-weight: 600;
					height: height(figcaption);
					line-height: 1.5;
				}
			}

			img {
				height: height(img);
				object-fit: contain;
				width: $width-total;
			}
		}

		.flex-child {
			align-content: center;
			align-items: center;
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			justify-content: center;
		}

		footer {
			font-weight: 300; 
			height: height(footer);

			div:nth-child(2) { font-size: 2.3vmin; }

			div:nth-child(2) > a { display: inline-flex; }

			div:nth-child(2) > a > img {
				height: 3.5vmin;
				width: 3.5vmin;
			}

			// > div > a {
			// 	margin-left: $margin;
			// 	margin-right: $margin;
			// }

			> div > a:before, > div > a:after {
				content: " ";
			}

			> .flex-child { height: height(footer-line); }
		}

		header {
			height: height(header);

			> h1 {
				font-size: 15vmin;
				font-weight: 800;
				margin-bottom: $margin;
				margin-top: $margin;
				text-align: center;
			}

			@each $horse, $color in $colors {
				> h1.#{$horse} {
					color: map-get($color, text);
					@include text-stroke(0.2vmin, map-get($color, stroke));
				}
			}
		}

		div#picture > figure > figcaption, footer {
			font-size: 3vmin;
			text-overflow: ellipsis;
		}
	}
}

/* – */